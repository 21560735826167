/* eslint-disable max-lines */
export * from '@oracle-cx-commerce/react-widgets';

// ENERPAC Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacHeaderContainer = () => import('./Enerpac/header/enerpac-header-container');
export const EnerpacHeaderTopBar = () => import('./Enerpac/header/enerpac-header-top-bar');
export const EnerpacHeaderTopBarHome = () => import('./Enerpac/header/enerpac-header-top-bar-home');
export const EnerpacHeaderTopBarPDP = () => import('./Enerpac/header/enerpac-header-top-bar-pdp');
export const EnerpacHeaderCollectionNavigationContainer = () =>
  import('./Enerpac/header/enerpac-header-collection-navigation-container');
export const EnerpacHeaderCollectionNavigationMobile = () =>
  import('./Enerpac/header/enerpac-header-collection-navigation-mobile');

export const EnerpacCountryStoreDesktop = () => import('./Enerpac/site/enerpac-country-store/desktop');
export const EnerpacCountryStoreMobile = () => import('./Enerpac/site/enerpac-country-store/mobile');
export const EnerpacError = () => import('./Enerpac/site/enerpac-error');

export const EnerpacProductSaleCTA = () => import('./Enerpac/product/enerpac-product-sale-cta');
export const EnerpacProductDetailsContainer = () => import('./Enerpac/product/enerpac-product-details-container');
export const EnerpacProductDetailsContainerSpecs = () =>
  import('./Enerpac/product/enerpac-product-details-container-specs');
export const EnerpacVerticalMarketContainer = () => import('./Enerpac/product/enerpac-vertical-market-container');
export const EnerpacProductQuickLinksContainer = () =>
  import('./Enerpac/product/enerpac-product-quick-links-container');
export const EnerpacProductImageViewer = () => import('./Enerpac/product/enerpac-product-image-viewer');
export const EnerpacProductSpecifications = () => import('./Enerpac/product/enerpac-product-specifications');
export const EnerpacProductDimensions = () => import('./Enerpac/product/enerpac-product-dimensions');
export const EnerpacProductVariants = () => import('./Enerpac/product/enerpac-product-variants');
export const EnerpacProductAllNewsPage = () => import('./Enerpac/product/enerpac-all-news-page');
export const EnerpacProductBestSellers = () => import('./Enerpac/product/enerpac-product-bestsellers');
export const EnerpacSimplexProductRedirect = () => import('./Enerpac/product/enerpac-simplex-product-redirect');

export const EnerpacHeroBanner = () => import('./Enerpac/homepage/enerpac-hero-banner');
export const EnerpacHeroBannerNew = () => import('./Enerpac/homepage/enerpac-hero-banner-new');
export const enerpacQuickLinks = () => import('./Enerpac/homepage/enerpac-quick-links');
export const EnerpacQuickLinksNew = () => import('./Enerpac/homepage/enerpac-quick-links-new');

export const EnerpacVerticalToolList = () => import('./Enerpac/vertical-market/enerpac-vertical-market-tools-list');
export const EnerpacVerticalMarketBanner = () => import('./Enerpac/vertical-market/enerpac-vertical-market-banner');
export const EnerpacVerticalMarketBannerSWind = () =>
  import('./Enerpac/vertical-market/enerpac-vertical-market-banner-SWind');
export const EnerpacVerticalMarketBannerMWind = () =>
  import('./Enerpac/vertical-market/enerpac-vertical-market-banner-MWind');
export const EnerpacVerticalMarketBannerEWind = () =>
  import('./Enerpac/vertical-market/enerpac-vertical-market-banner-EWind');
export const EnerpacVerticalMarketStories = () => import('./Enerpac/vertical-market/enerpac-vertical-market-stories');
export const EnerpacVerticalMarketInsights = () => import('./Enerpac/vertical-market/enerpac-vertical-market-insights');

export const EnerpacVerticalMarketSolutions = () =>
  import('./Enerpac/vertical-market/enerpac-vertical-market-solutions');

export const EnerpacVerticalToolListRail = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-tools-list-rail');
export const EnerpacVerticalMarketBannerRail = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-banner-rail');
export const EnerpacVerticalMarketBannerRailExperts = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-banner-rail-experts');
export const EnerpacVerticalMarketBannerRailManufacturers = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-banner-rail-manufacturers');
export const EnerpacVerticalMarketStoriesRail = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-stories-rail');
export const EnerpacVerticalMarketInsightsRail = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-insights-rail');
export const EnerpacVerticalMarketSolutionsRail = () =>
  import('./Enerpac/vertical-market-rail/enerpac-vertical-market-solutions-rail');

// ENERPAC Inf Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacInfInsights = () => import('./Enerpac/vmInfrastructure/vm-inf-common/enerpac-inf-insights');
export const EnerpacInfProfessionals = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-common/enerpac-inf-professionals');
export const EnerpacInfStories = () => import('./Enerpac/vmInfrastructure/vm-inf-common/enerpac-inf-stories');
export const EnerpacInfLandingBanner = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-landing/enerpac-inf-landing-banner');
export const EnerpacInfLandingTools = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-landing/enerpac-inf-landing-tools');
export const EnerpacInfSolutions = () => import('./Enerpac/vmInfrastructure/vm-inf-landing/enerpac-inf-solutions');
export const EnerpacInfSuccesses = () => import('./Enerpac/vmInfrastructure/vm-inf-landing/enerpac-inf-successes');
export const EnerpacInfBuildersBanner = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-builders/enerpac-inf-builders-banner');
export const EnerpacInfBuildersCollections = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-builders/enerpac-inf-builders-collections');
export const EnerpacInfBuildersResources = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-builders/enerpac-inf-builders-resources');
export const EnerpacInfBuildersVideo = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-builders/enerpac-inf-builders-video');
export const EnerpacInfContractorsBanner = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-contractors/enerpac-inf-contractors-banner');
export const EnerpacInfContractorsCollections = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-contractors/enerpac-inf-contractors-collections');
export const EnerpacInfContractorsResources = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-contractors/enerpac-inf-contractors-resources');
export const EnerpacInfContractorsVideo = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-contractors/enerpac-inf-contractors-video');
export const EnerpacInfSpecialistsBanner = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-specialists/enerpac-inf-specialists-banner');
export const EnerpacInfSpecialistsCollections = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-specialists/enerpac-inf-specialists-collections');
export const EnerpacInfSpecialistsResources = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-specialists/enerpac-inf-specialists-resources');
export const EnerpacInfSpecialistsVideo = () =>
  import('./Enerpac/vmInfrastructure/vm-inf-specialists/enerpac-inf-specialists-video');
// END Wind Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

// ENERPAC Toolbox Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacTBFeaturedTools = () =>
  import('./Enerpac/toolbox-feature/toolbox-landing/enerpac-toolbox-featured-tools');
export const EnerpacTBProfessionals = () =>
  import('./Enerpac/toolbox-feature/toolbox-landing/enerpac-toolbox-professionals');
export const EnerpacTBSolutions = () => import('./Enerpac/toolbox-feature/toolbox-landing/enerpac-toolbox-solutions');
export const EnerpacTBSuccesses = () => import('./Enerpac/toolbox-feature/toolbox-landing/enerpac-toolbox-successes');
export const EnerpacTBLandingBanner = () => import('./Enerpac/toolbox-feature/enerpac-toolbox-landing-banner');
// END Wind Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

export const EnerpacLPRP70a = () => import('./Enerpac/landingPages/enerpac-lpRP70a');
export const EnerpacPPH40 = () => import('./Enerpac/landingPages/enerpac-pph40');
export const EnerpacLPTL248 = () => import('./Enerpac/landingPages/enerpac-lpTL248');
export const EnerpacLPSCXC2 = () => import('./Enerpac/landingPages/enerpac-lp-sx-xc2');
export const EnerpacToolbox = () => import('./Enerpac/landingPages/enerpac-lp-toolbox');
export const EnerpacLPSuccess = () => import('./Enerpac/landingPages/enerpac-lp-success');

export const EnerpacToolBoxPromo = () => import('./Enerpac/common/enerpac-toolbox-promo');

export const EnerpacFooter = () => import('./Enerpac/footer/enerpac-footer');
export const EnerpacFooterHomePage = () => import('./Enerpac/footer/enerpac-footer-homepage');
export const enerpacHistory2 = () => import('./Enerpac/homepage/enerpac-history-2');

export const simplexPowerPumpSelectionGuide = () => import('./Enerpac/tkSimplex/simplex-power-pumps-selection-guide');

export const simplexCylinderSelectionGuide = () => import('./Enerpac/tkSimplex/simplex-cylinder-selection-guide');
export const simplexMechanicalSelectionGuide = () =>
  import('./Enerpac/tkSimplex/simplex-mechanical-jack-selection-guide');
export const simplexHydraulicSelectionGuide = () =>
  import('./Enerpac/tkSimplex/simplex-hydraulic-press-selection-guide');
export const simplexCylinderandPumpSystems = () =>
  import('./Enerpac/tkSimplex/simplex-cylinder-and-pump-system-arrangements');

export const simplexHandPumpCylinderGuide = () => import('./Enerpac/tkSimplex/simplex-hand-pump-cylinder-guide');

export const simplexSafetyTips = () => import('./Enerpac/tkSimplex/simplex-hydraulic-safety-tips');
export const simplexBasicHydraulics = () => import('./Enerpac/tkSimplex/simplex-basic-hydraulics');
export const simplexForceSpeedCalc = () => import('./Enerpac/tkSimplex/simplex-force-speed-calc');
export const simplexConversionChart = () => import('./Enerpac/tkSimplex/simplex-conversion-chart');
export const simplexProductDateCodes = () => import('./Enerpac/tkSimplex/simplex-product-date-codes');

export const simplexAbout = () => import('./Enerpac/tkSimplex/simplex-about');

export const EnerpacLocations = () => import('./Enerpac/common/enerpac-locations');
export const EnerpacNewProducts = () => import('./Enerpac/common/enerpac-new-products');
export const EnerpacRecentlyViewed = () => import('./Enerpac/common/enerpac-recently-viewed');
export const EnerpacTopSellers = () => import('./Enerpac/common/enerpac-top-sellers');
export const EnerpacTopSellersNew = () => import('./Enerpac/homepage/enerpac-top-sellers-new');
export const EnerpacFeedApplications = () => import('./Enerpac/common/enerpac-feed-applications');
export const EnerpacBlog = () => import('./Enerpac/common/enerpac-blog');
export const EnerpacNews = () => import('./Enerpac/common/enerpac-news');
export const EnerpacContainer = () => import('./Enerpac/common/enerpac-container');
export const enerpacFirstPageView = () => import('./Enerpac/common/enerpac-first-page-view');
export const enerpacPromoPopUp = () => import('./Enerpac/common/enerpac-promo-pop-up');
export const EnerpacResponsiveContainer = () => import('./Enerpac/common/enerpac-responsive-container');
export const EnerpacNotifications = () => import('./Enerpac/common/enerpac-notifications');
export const EnerpacContinueShoppingButton = () => import('./Enerpac/common/enerpac-continue-shopping-button');
export const EnerpacSerialNumberLookup = () => import('./Enerpac/common/enerpac-serial-number-lookup');
export const EnerpacDownloads = () => import('./Enerpac/common/enerpac-downloads');
export const EnerpacSimplexDownloads = () => import('./Enerpac/common/simplex-downloads');
export const EnerpacAboutUs = () => import('./Enerpac/common/enerpac-about-us');
export const EnerpacComparisonTool = () => import('./Enerpac/common/enerpac-comparison-tool');
export const SuccessPage = () => import('./Enerpac/common/enerpac-form-success');
export const EnerpacHSSEQ = () => import('./Enerpac/common/enerpac-hsseq');
export const EnerpacMapPolicy = () => import('./Enerpac/common/enerpac-map-policy');
export const EnerpacNationalDistributors = () => import('./Enerpac/common/enerpac-national-distributors');
export const EnerpacTradeshowEvents = () => import('./Enerpac/common/enerpac-tradeshows/enerpac-tradeshows-events');

export const EnerpacBatteryCompliance = () => import('./Enerpac/common/enerpac-battery-compliance');

export const EnerpacPrivacyPolicy = () => import('./Enerpac/common/enerpac-privacy-policy');
export const EnerpacPrivacyPolicyDE = () => import('./Enerpac/common/enerpac-privacy-policy-de');
export const EnerpacPrivacyPolicyIT = () => import('./Enerpac/common/enerpac-privacy-policy-it');
export const EnerpacPrivacyPolicyFR = () => import('./Enerpac/common/enerpac-privacy-policy-fr');
export const EnerpacPrivacyPolicyES = () => import('./Enerpac/common/enerpac-privacy-policy-es');

export const enerpacEmarsys = () => import('./Enerpac/common/enerpac-emarsys');
export const enerpacToolBoxPromo = () => import('./Enerpac/common/enerpac-toolbox-promo');
export const enerpacRoadShow = () => import('./Enerpac/common/enerpac-roadshowForm');
export const enerpacRoadShowHengelo = () => import('./Enerpac/common/enerpac-roadshowFormHengelo');
export const enerpacRoadShowRegina = () => import('./Enerpac/common/enerpac-roadshowFormRegina');
export const enerpacRoadShowSaskatoon = () => import('./Enerpac/common/enerpac-roadshowFormSaskatoon');
export const enerpacTBPromotionForm = () => import('./Enerpac/common/enerpac-toolbox-promotion-form');
export const enerpacRoadShowPSSI = () => import('./Enerpac/common/enerpac-roadshowFormPSSI');
export const enerpacRoadShowFW = () => import('./Enerpac/common/enerpac-roadshowFormFW');
export const enerpacRoadShowAmericas = () => import('./Enerpac/common/enerpac-roadshowFormAmericas');
export const EnerpacExperienceDaysAmericas = () => import('./Enerpac/common/enerpac-experienceDaysAmerica');
export const EnerpacGonzalesGrandOpening = () => import('./Enerpac/common/enerpac-gonzales-grandOpening');

export const EnerpacQuoteRequestForm = () => import('./Enerpac/common/enerpac-quoteRequest-form');
export const EnerpacContactUs = () => import('./Enerpac/common/enerpac-contactUs-React');
export const EnerpacLiveChat = () => import('./Enerpac/common/enerpac-live-chat');

export const EnerpacCpl = () => import('./Enerpac/clp/enerpac-cpl');
export const EnerpacCLPContainer = () => import('./Enerpac/clp/enerpac-clp-container');
export const EnerpacCLPBreadCrumb = () => import('./Enerpac/clp/enerpac-clp-breadcrumb');
export const EnerpacCLPCategoryContent = () => import('./Enerpac/clp/enerpac-clp-category-content');
export const EnerpacCLPChildCategories = () => import('./Enerpac/clp/enerpac-clp-child-categories');
export const enerpacClpValprop = () => import('./Enerpac/clp/enerpac-clp-valprop');
export const EnerpacClpNewsFeed = () => import('./Enerpac/clp/enerpac-clp-news-feed');
export const EnerpacCategoryLongDescription = () =>
  import('./Enerpac/product-listing/enerpac-category-long-description');
export const EnerpacCategoryLongDescription2 = () =>
  import('./Enerpac/product-listing/enerpac-category-long-description2');
export const EnerpacCategoryDescription = () => import('./Enerpac/product-listing/enerpac-category-description');

export const enerpacHomepageFeeds = () => import('./Enerpac/homepage/enerpac-homepage-feeds');
export const EnerpacHomepageFeature = () => import('./Enerpac/homepage/enerpac-homepage-feature');
export const EnerpacHomepageLogin = () => import('./Enerpac/homepage/enerpac-homepage-login');
export const EnerpacHomepageProductGrid = () => import('./Enerpac/homepage/enerpac-homepage-product-grid');
export const EnerpacHomepageNewProducts = () => import('./Enerpac/homepage/enerpac-homepage-new-products');

export const EnerpacUserProfileLinksDesktop = () => import('./Enerpac/session/enerpac-user-profile-links/desktop');
export const EnerpacUserProfileLinksMobile = () => import('./Enerpac/session/enerpac-user-profile-links/mobile');
export const EnerpacLogin = () => import('./Enerpac/session/enerpac-login');

export const EnerpacCartContainer = () => import('./Enerpac/cart/enerpac-cart-container');
export const EnerpacCheckoutShippingCartContainer = () =>
  import('./Enerpac/cart/enerpac-checkout-shipping-cart-container');
export const EnerpacMiniCartDesktop = () => import('./Enerpac/cart/enerpac-mini-cart/desktop');
export const EnerpacMiniCartMobile = () => import('./Enerpac/cart/enerpac-mini-cart/mobile');
export const EnerpacCartItemDetails = () => import('./Enerpac/cart/enerpac-cart-item-details');

export const EnerpacApplicationPowerGen = () => import('./Enerpac/product-listing/enerpac-application-power-gen');

export const EnerpacSearchBoxDesktop = () => import('./Enerpac/product-listing/enerpac-search-box/desktop');
export const EnerpacSearchBoxMobile = () => import('./Enerpac/product-listing/enerpac-search-box/mobile');
export const EnerpacProductInventoryStatus = () => import('./Enerpac/product/enerpac-product-inventory-status');
export const EnerpacProductComparison = () => import('./Enerpac/product/enerpac-product-comparison');

export const EnerpacCheckoutSingleShippingDetails = () =>
  import('./Enerpac/checkout/enerpac-checkout-single-shipping-details');
export const EnerpacCheckoutLogin = () => import('./Enerpac/checkout/enerpac-checkout-login');
export const EnerpacCheckoutShippingContainer = () => import('./Enerpac/checkout/enerpac-checkout-shipping-container');
export const EnerpacCheckoutCustomerEmail = () => import('./Enerpac/checkout/enerpac-checkout-customer-email');
export const EnerpacCheckoutInvoice = () => import('./Enerpac/checkout/enerpac-checkout-invoice');
export const EnerpacCheckoutOrderConfirmation = () => import('./Enerpac/checkout/enerpac-checkout-order-confirmation');
export const EnerpacCheckoutPurchaseSurvey = () => import('./Enerpac/checkout/enerpac-checkout-purchase-survey');
export const EnerpacBlankComponent = () => import('./Enerpac/common/enerpac-BLANKCOMPONENT');

// ENERPAC Wind Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacWindLandingBanner = () => import('./Enerpac/vm/vm-wind-landing/enerpac-wind-landing-banner');
export const EnerpacWindLandingTools = () => import('./Enerpac/vm/vm-wind-landing/enerpac-wind-landing-tools');
export const EnerpacWindSolutions = () => import('./Enerpac/vm/vm-wind-landing/enerpac-wind-solutions');
export const EnerpacWindSuccesses = () => import('./Enerpac/vm/vm-wind-landing/enerpac-wind-successes');
export const EnerpacWindProfessionals = () => import('./Enerpac/vm/vm-wind-common/enerpac-wind-professionals');
export const EnerpacWindStories = () => import('./Enerpac/vm/vm-wind-common/enerpac-wind-stories');
export const EnerpacWindInsights = () => import('./Enerpac/vm/vm-wind-common/enerpac-wind-insights');
export const EnerpacWindCnTBanner = () => import('./Enerpac/vm/vm-wind-CnT/enerpac-wind-CnT-banner');
export const EnerpacWindCnTCollections = () => import('./Enerpac/vm/vm-wind-CnT/enerpac-wind-CnT-collections');
export const EnerpacWindCnTResources = () => import('./Enerpac/vm/vm-wind-CnT/enerpac-wind-CnT-resources');
export const EnerpacWindCnTInteractive = () => import('./Enerpac/vm/vm-wind-CnT/enerpac-wind-CnT-interactive');
export const EnerpacWindCnTVideo = () => import('./Enerpac/vm/vm-wind-CnT/enerpac-wind-CnT-video');

export const EnerpacWindMBanner = () => import('./Enerpac/vm/vm-wind-manufacturers/enerpac-wind-m-banner');
export const EnerpacWindMCollections = () => import('./Enerpac/vm/vm-wind-manufacturers/enerpac-wind-m-collections');
export const EnerpacWindMResources = () => import('./Enerpac/vm/vm-wind-manufacturers/enerpac-wind-m-resources');
export const EnerpacWindMInteractive = () => import('./Enerpac/vm/vm-wind-manufacturers/enerpac-wind-m-interactive');
export const EnerpacWindMVideo = () => import('./Enerpac/vm/vm-wind-manufacturers/enerpac-wind-m-video');

export const EnerpacWindSBanner = () => import('./Enerpac/vm/vm-wind-service/enerpac-wind-s-banner');
export const EnerpacWindSCollections = () => import('./Enerpac/vm/vm-wind-service/enerpac-wind-s-collections');
export const EnerpacWindSResources = () => import('./Enerpac/vm/vm-wind-service/enerpac-wind-s-resources');
export const EnerpacWindSVideo = () => import('./Enerpac/vm/vm-wind-service/enerpac-wind-s-video');

// END Wind Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

// ENERPAC Rail Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacRailLandingBanner = () => import('./Enerpac/vm/vm-rail-landing/enerpac-rail-landing-banner');
export const EnerpacRailLandingTools = () => import('./Enerpac/vm/vm-rail-landing/enerpac-rail-landing-tools');
export const EnerpacRailSolutions = () => import('./Enerpac/vm/vm-rail-landing/enerpac-rail-solutions');
export const EnerpacRailSuccesses = () => import('./Enerpac/vm/vm-rail-landing/enerpac-rail-successes');

export const EnerpacRailProfessionals = () => import('./Enerpac/vm/vm-rail-common/enerpac-rail-professionals');
export const EnerpacRailStories = () => import('./Enerpac/vm/vm-rail-common/enerpac-rail-stories');
export const EnerpacRailInsights = () => import('./Enerpac/vm/vm-rail-common/enerpac-rail-insights');

export const EnerpacRailMBanner = () => import('./Enerpac/vm/vm-rail-manufacturers/enerpac-rail-m-banner');
export const EnerpacRailMCollections = () => import('./Enerpac/vm/vm-rail-manufacturers/enerpac-rail-m-collections');
export const EnerpacRailMResources = () => import('./Enerpac/vm/vm-rail-manufacturers/enerpac-rail-m-resources');
export const EnerpacRailMInteractive = () => import('./Enerpac/vm/vm-rail-manufacturers/enerpac-rail-m-interactive');
export const EnerpacRailMTInteractive = () => import('./Enerpac/vm/vm-rail-maintenance/enerpac-rail-mt-interactive');
export const EnerpacRailMVideo = () => import('./Enerpac/vm/vm-rail-manufacturers/enerpac-rail-m-video');

export const EnerpacRailMTBanner = () => import('./Enerpac/vm/vm-rail-maintenance/enerpac-rail-mt-banner');
export const EnerpacRailMTCollections = () => import('./Enerpac/vm/vm-rail-maintenance/enerpac-rail-mt-collections');
export const EnerpacRailMTResources = () => import('./Enerpac/vm/vm-rail-maintenance/enerpac-rail-mt-resources');
export const EnerpacRailMTVideo = () => import('./Enerpac/vm/vm-rail-maintenance/enerpac-rail-mt-video');
// END Rail Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

// ENERPAC Email Campaign Landing Pages -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacRailEfficiencyLP = () => import('./Enerpac/landingPages/enerpacRailEfficiencyLP');

// END ENERPAC Email Campaign Landing Pages -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const EnerpacProfileOrderHistory = () => import('./Enerpac/profile/enerpac-profile-order-history');
export const EnerpacProfileOrderDetailsContainer = () =>
  import('./Enerpac/profile/enerpac-profile-order-details-container');
export const EnerpacOrderStatus = () => import('./Enerpac/profile/enerpac-order-status');
export const EnerpacProfileRegistration = () => import('./Enerpac/profile/enerpac-profile-registration');

export const EnerpacProfileEmailMarketingPreferencesSummary = () =>
  import('./Enerpac/profile/enerpac-profile-email-marketing-preferences-summary');
export const EnerpacProfileRecentOrders = () => import('./Enerpac/profile/enerpac-profile-recent-orders');
export const EnerpacProfileSavedCardSummary = () => import('./Enerpac/profile/enerpac-profile-saved-card-summary');
export const EnerpacProfileWelcome = () => import('./Enerpac/profile/enerpac-profile-welcome');
export const EnerpacProfileDetailsSummary = () => import('./Enerpac/profile/enerpac-profile-details-summary');
export const EnerpacProfileAddressBook = () => import('./Enerpac/profile/enerpac-profile-address-book');
export const EnerpacAccountAddressBook = () => import('./Enerpac/profile/enerpac-account-address-book');
export const EnerpacProfileAddressBookSummary = () => import('./Enerpac/profile/enerpac-profile-address-book-summary');
export const EnerpacProfileEmailMarketingPreferences = () =>
  import('./Enerpac/profile/enerpac-profile-email-marketing-preferences');

export const EnerpacFacetedNavigationDesktop = () =>
  import('./Enerpac/product-listing/enerpac-faceted-navigation/desktop');
export const EnerpacFacetedNavigationMobile = () =>
  import('./Enerpac/product-listing/enerpac-faceted-navigation/mobile');
export const EnerpacCategoryOverview = () => import('./Enerpac/product-listing/enerpac-category-overview');
export const EnerpacRentalToggle = () => import('./Enerpac/product-listing/enerpac-rental-toggle');
export const EnerpacCategoryOverviewRental = () => import('./Enerpac/product-listing/enerpac-category-overview-rental');
export const EnerpacDynamicProductListingContainer = () =>
  import('./Enerpac/product-listing/enerpac-dynamic-product-listing-container');
export const EnerpacDynamicProductListingContainerRental = () =>
  import('./Enerpac/product-listing/enerpac-dynamic-product-listing-container-rental');

export const EnerpacPLPInfiniteScroll = () => import('./Enerpac/product-listing/enerpac-plp-infinite-scroll');
export const EnerpacPLPTracking = () => import('./Enerpac/product-listing/enerpac-plp-tracking');
export const EnerpacRentalListing = () => import('./Enerpac/product-listing/enerpac-rental-listing');
export const EnerpacProductComparableModels = () => import('./Enerpac/product/enerpac-product-comparable-models');
export const EnerpacProductUpsell = () => import('./Enerpac/product/enerpac-product-upsell');
export const EnerpacBundlePurchase = () => import('./Enerpac/product/enerpac-product-bundle-purchase');
export const EnerpacCompatibleProducts = () => import('./Enerpac/product/enerpac-product-compatible-products');

export const EnerpacProductAddToCartButton = () => import('./Enerpac/product/enerpac-product-add-to-cart-button');

export const EnerpacProductCrossSell = () => import('./Enerpac/product/enerpac-product-cross-sell');
export const EnerpacProductAccessories = () => import('./Enerpac/product/enerpac-product-accessories');
export const EnerpacProductCompleteSystem = () => import('./Enerpac/product/enerpac-product-complete-system');
export const EnerpacProductCompleteSystemInPage = () =>
  import('./Enerpac/product/enerpac-product-complete-system-in-page');
export const EnerpacRecentlyViewedPdp = () => import('./Enerpac/product/enerpac-recently-viewed-pdp');
export const EnerpacProductSetIncludes = () => import('./Enerpac/product/enerpac-product-set-includes');
export const EnerpacProductRepairParts = () => import('./Enerpac/product/enerpac-product-repair-parts');
export const EnerpacPdpNewsFeed = () => import('./Enerpac/product/enerpac-pdp-news-feed');

export const EnerpacProductResultName = () => import('./Enerpac/product-listing/enerpac-product-result-name');
export const EnerpacProductResultNameRental = () =>
  import('./Enerpac/product-listing/enerpac-product-result-name-rental');
export const EnerpacProductQuantity = () => import('./Enerpac/product/enerpac-product-quantity');
export const enerpacProductName = () => import('./Enerpac/product/enerpac-product-name');
export const enerpacProductTitle = () => import('./Enerpac/product/enerpac-product-title');
export const enerpacProductLongDescription = () => import('./Enerpac/product/enerpac-product-long-description');
export const enerpacProductPrice = () => import('./Enerpac/product/enerpac-product-price');
export const EnerpacProductResultQuantity = () => import('./Enerpac/product-listing/enerpac-product-result-quantity');
export const EnerpacProductResultAddToCartButton = () =>
  import('./Enerpac/product-listing/enerpac-product-result-add-to-cart-button');
export const EnerpacProductResultAddToCartButtonRental = () =>
  import('./Enerpac/product-listing/enerpac-product-result-add-to-cart-button-rental');

export const EnerpacDynamicProductList = () => import('./Enerpac/product-listing/enerpac-dynamic-product-list');
export const enerpacAllProductsTitle = () => import('./Enerpac/product-listing/enerpac-all-products-title');
export const EnerpacSearchTermsSummary = () => import('./Enerpac/product-listing/enerpac-search-terms-summary');

export const EnerpacProductRentalTab = () => import('./Enerpac/product/enerpac-product-rental-tab');
export const EnerpacProductQuickLinks = () => import('./Enerpac/product/enerpac-product-quick-links');
export const enerpacNewProductHeader = () => import('./Enerpac/product/enerpac-new-product-header');

export const enerpacProductDescriptionSection = () => import('./Enerpac/product/enerpac-product-description-section');
export const EnerpacProductRentalDistributorLinks = () =>
  import('./Enerpac/product/enerpac-product-rental-distributor-links');
export const EnerpacProductRentalDistributorLinksWhereToBuy = () =>
  import('./Enerpac/product/enerpac-product-links-where-to-buy');

export const EnerpacSpecImages = () => import('./Enerpac/product/enerpac-spec-images');

export const EnerpacProductDownloads = () => import('./Enerpac/product/enerpac-product-downloads');
export const EnerpacDistributorsContainer = () => import('./Enerpac/distributors/enerpac-distributors-container');
export const EnerpacDistributorsForm = () => import('./Enerpac/distributors/enerpac-distributors-form');
export const EnerpacDistributorResults = () => import('./Enerpac/distributors/enerpac-distributor-results');
export const EnerpacDistributorMap = () => import('./Enerpac/distributors/enerpac-distributor-map');

export const EnerpacCheckoutBackToPreviousButton = () =>
  import('./Enerpac/checkout/enerpac-checkout-back-to-previous-button');
export const EnerpacCheckoutButton = () => import('./Enerpac/checkout/enerpac-checkout-button');
export const EnerpacCheckoutCompletePaymentButton = () =>
  import('./Enerpac/checkout/enerpac-checkout-complete-payment-button');
export const EnerpacCheckoutContinueToPaymentButton = () =>
  import('./Enerpac/checkout/enerpac-checkout-continue-to-payment-button');
export const EnerpacCheckoutContinueToReviewOrderButton = () =>
  import('./Enerpac/checkout/enerpac-checkout-continue-to-review-order-button');
export const EnerpacCheckoutPaymentsContainer = () => import('./Enerpac/checkout/enerpac-checkout-payments-container');
export const EnerpacCheckoutProgressTracker = () => import('./Enerpac/checkout/enerpac-checkout-progress-tracker');
export const EnerpacCheckoutCreditCard = () => import('./Enerpac/checkout/enerpac-checkout-credit-card');
export const EnerpacCartOrderSummary = () => import('./Enerpac/cart/enerpac-cart-order-summary');
export const EnerpacCartPayInStore = () => import('./Enerpac/cart/enerpac-cart-pay-in-store');
export const EnerpacPromotionCodeEntry = () => import('./Enerpac/cart/enerpac-promotion-code-entry');
export const EnerpacPromotionsDisplay = () => import('./Enerpac/cart/enerpac-promotions-display');
export const EnerpacCartRentalShipping = () => import('./Enerpac/cart/enerpac-cart-rental-shipping');
export const EnerpacCheckoutPaymentMethodsContainer = () =>
  import('./Enerpac/checkout/enerpac-checkout-payment-methods-container');
export const EnerpacCheckoutGiftCard = () => import('./Enerpac/checkout/enerpac-checkout-gift-card');
export const EnerpacCheckoutPayInStore = () => import('./Enerpac/checkout/enerpac-checkout-pay-in-store');

export const EnerpacCheckoutOrderConfirmationContainer = () =>
  import('./Enerpac/checkout/enerpac-checkout-order-confirmation-container');
export const EnerpacCheckoutPayLater = () => import('./Enerpac/checkout/enerpac-checkout-pay-later');
export const EnerpacCheckoutPayerAuthentication = () =>
  import('./Enerpac/checkout/enerpac-checkout-payer-authentication');
export const EnerpacCheckoutPendingPaymentHeader = () =>
  import('./Enerpac/checkout/enerpac-checkout-pending-payment-header');
export const EnerpacCheckoutPlaceOrderButton = () => import('./Enerpac/checkout/enerpac-checkout-place-order-button');
export const EnerpacCheckoutRegistration = () => import('./Enerpac/checkout/enerpac-checkout-registration');
export const EnerpacCheckoutReviewAndPlaceQuotedOrderButton = () =>
  import('./Enerpac/checkout/enerpac-checkout-review-and-place-quoted-order-button');
export const EnerpacCheckoutReviewOrderContainer = () =>
  import('./Enerpac/checkout/enerpac-checkout-review-order-container');
export const EnerpacCheckoutScheduledOrder = () => import('./Enerpac/checkout/enerpac-checkout-scheduled-order');

export const EnerpacShippingInformation = () => import('./Enerpac/profile/enerpac-shipping-information');
export const EnerpacPaymentInformation = () => import('./Enerpac/profile/enerpac-payment-information');
export const EnerpacProfileAddressForm = () => import('./Enerpac/profile/enerpac-profile-address-form');
export const enerpacClpHeader = () => import('./Enerpac/clp/enerpac-clp-header');
export const enerpacWindHeader = () => import('./Enerpac/product/enerpac-wind-header');
export const enerpacEqualizerHeader = () => import('./Enerpac/product/enerpac-equalizer-header');

export const enerpacBoltingHeader = () => import('./Enerpac/product/enerpac-bolting-header');
export const EnerpacRentalHeader = () => import('./Enerpac/product/enerpac-rental-header');

export const EnerpacNewsPDPLongDescription = () =>
  import('./Enerpac/product/enerpac-pdp-news-product-long-description');
export const EnerpacStaticContent = () => import('./Enerpac/product/enerpac-static-content');
export const EnerpacApplicationPDPLongDescription = () =>
  import('./Enerpac/product/enerpac-pdp-application-product-long-description');
export const EnerpacProductBreadcrumbs = () => import('./Enerpac/product/enerpac-product-breadcrumbs');
export const enerpacMachineMovingHeader = () => import('./Enerpac/product/enerpac-machine-moving-header');
export const enerpacHltHeader = () => import('./Enerpac/product/enerpac-hlt-header');
export const enerpacSimplexHeader = () => import('./Enerpac/product/enerpac-simplex-header');

export const EnerpacSortResults = () => import('./Enerpac/product-listing/enerpac-sort-results');
export const EnerpacProductResultPrice = () => import('./Enerpac/product-listing/enerpac-product-result-price');
export const EnerpacProductResultPriceRental = () =>
  import('./Enerpac/product-listing/enerpac-product-result-price-rental');
export const EnerpacDynamicBreadcrumbs = () => import('./Enerpac/product-listing/enerpac-dynamic-breadcrumbs');
export const EnerpacDynamicBreadcrumbsRental = () =>
  import('./Enerpac/product-listing/enerpac-dynamic-breadcrumbs-rental');
export const EnerpacProductResultRequestQuote = () =>
  import('./Enerpac/product-listing/enerpac-product-result-request-quote');
export const EnerpacProductResultImage = () => import('./Enerpac/product-listing/enerpac-product-result-image');
export const EnerpacProductResultImageRental = () =>
  import('./Enerpac/product-listing/enerpac-product-result-image-rental');
export const EnerpacGtagCheckout = () => import('./Enerpac/gTag/enerpac-gtag-checkout');
export const EnerpacGtagOrderConfirmation = () => import('./Enerpac/gTag/enerpac-gtag-order-confirmation');
export const EnerpacGtagPDP = () => import('./Enerpac/gTag/enerpac-gtag-pdp');

export const EnerpacDistributorHeader = () => import('./Enerpac/training/enerpac-distributor-header');
export const EnerpacToolOwnerHeader = () => import('./Enerpac/training/enerpac-tool-owner-header');
export const EnerpacOrderConfirmationPaymentInformation = () =>
  import('./Enerpac/checkout/enerpac-checkout-order-confirmation-payment-information');
export const EnerpacOrderConfirmationShippingInformation = () =>
  import('./Enerpac/checkout/enerpac-checkout-order-confirmation-shipping-information');

export const EnerpacCancellationPolicy = () => import('./Enerpac/common/enerpac-cancellation-policy');
export const EnerpacCategoryContentContainer = () =>
  import('./Enerpac/product-listing/enerpac-category-content-container');

export const EnerpacProfileReviewOrders = () => import('./Enerpac/profile/enerpac-profile-review-orders');
export const EnerpacFlangeGuru = () => import('./Enerpac/common/enerpac-flange-guru');
export const EnerpacNewProductOptIn = () => import('./Enerpac/common/enerpac-new-products-opt-in');
export const EnerpacCalibrationForm = () => import('./Enerpac/common/enerpac-calibration-form');
export const EnerpacCalibrationServices = () => import('./Enerpac/common/enerpac-calibration-services');
export const EnerpacDecommissioningForm = () => import('./Enerpac/common/enerpac-decommissioning-form');
export const EnerpacDecommissioningCaseStudy = () => import('./Enerpac/common/enerpac-decommissioning-case-study');
export const EnerpacDecommissioningCaseStudySuccess = () =>
  import('./Enerpac/common/enerpac-decommissioning-case-study-success');
export const EnerpacCanonicalTagsManager = () => import('./Enerpac/common/enerpac-canonical-tags-manager');

export const EnerpacCylinderPumpSpeedGuide = () => import('./Enerpac/common/enerpac-cylinder-pump-speed-guide');
export const enerpacCylindersHeader = () => import('./Enerpac/product/enerpac-cylinders-header');

export const EnerpacUSProductsHeader = () => import('./Enerpac/product/enerpac-usProducts-header');
export const EnerpacBoltingSolutionsFeeds = () => import('./Enerpac/product/enerpac-bolting-solutions-feeds');
export const EnerpacHLTFeeds = () => import('./Enerpac/product/enerpac-hlt-feeds');
export const EnerpacLazyLoadingContainer = () => import('./Enerpac/common/enerpac-lazy-loading-container');
export const EnerpacHeaderAddToCartModal = () => import('./Enerpac/header/enerpac-header-add-to-cart-modal');
export const EnerpacHeaderAddToCartModalv2 = () => import('./Enerpac/header/enerpac-header-add-to-cart-modal-v2');
export const EnerpacImage = () => import('./Enerpac/common/enerpac-image');
export const EnerpacProductDetailsTabContainer = () =>
  import('./Enerpac/product/enerpac-product-details-tab-container');
export const EnerpacWindVideo = () => import('./Enerpac/common/enerpac-wind-video');
export const EnerpacWindResourcesManufacturer = () => import('./Enerpac/common/enerpac-wind-resources-manufacturer');
export const EnerpacWindResourcesLogistics = () => import('./Enerpac/common/enerpac-wind-resources-logistics');
export const EnerpacWindResourcesMaintenacne = () => import('./Enerpac/common/enerpac-wind-resources-maintenance');
export const EnerpacWindTools = () => import('./Enerpac/common/enerpac-wind-tools');

export const EnerpacRailVideo = () => import('./Enerpac/common/enerpac-rail-video');
export const EnerpacRailTools = () => import('./Enerpac/common/enerpac-rail-tools');
export const EnerpacWindToolsExperts = () => import('./Enerpac/common/enerpac-wind-tools-experts');
export const EnerpacWindToolsManufacturers = () => import('./Enerpac/common/enerpac-wind-tools-manufacturers');
export const EnerpacWindToolsServices = () => import('./Enerpac/common/enerpac-wind-tools-services');

export const EnerpacCheckoutRentalInvoice = () => import('./Enerpac/checkout/enerpac-checkout-rental-invoice');
export const EnerpacSinglePageCheckoutContainer = () => import('./Enerpac/checkout-v2/enerpac-sp-checkout-container');
export const EnerpacSinglePageCheckoutAddresses = () => import('./Enerpac/checkout-v2/enerpac-sp-checkout-addresses');
export const EnerpacSinglePageCheckoutOrderSummary = () =>
  import('./Enerpac/checkout-v2/enerpac-sp-checkout-order-summary');
export const EnerpacSinglePageCheckoutPaymentMethods = () =>
  import('./Enerpac/checkout-v2/enerpac-sp-checkout-payment-methods');
export const EnerpacSinglePageCheckoutShippingMethods = () =>
  import('./Enerpac/checkout-v2/enerpac-sp-checkout-shipping-methods');
export const EnerpacSinglePageCheckoutSubmitButton = () =>
  import('./Enerpac/checkout-v2/enerpac-sp-checkout-submit-order-button');
export const EnerpacSinglePageCheckoutPromotions = () => import('./Enerpac/checkout-v2/enerpac-sp-checkout-promotions');

export const EnerpacDistributorLocator = () => import('./Enerpac/distributors/enerpac-distributor-locator');

// HYDRATIGHT Widgets -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const HydratightFooter = () => import('./Hydratight/footer/hydratight-footer');

export const hydratightServicesGrid = () => import('./Hydratight/homepage/hydratight-services-grid');
export const hydratightOnlineServicesGrid = () =>
  import('./Hydratight/online-services/hydratight-online-services-grid');
export const hydratightOSWhy = () => import('./Hydratight/online-services/hydratight-os-why');
export const hydratightOSBenefits = () => import('./Hydratight/online-services/hydratight-os-benefits');
export const hydratightControlledBoltingGrid = () =>
  import('./Hydratight/online-services/hydratight-controlled-bolting-grid');
export const hydratightCBBenefits = () => import('./Hydratight/online-services/hydratight-cb-benefits');
export const hydratightOSAccordians = () => import('./Hydratight/online-services/hydratight-os-accordians');
export const hydratightAboutHT = () => import('./Hydratight/homepage/hydratight-about-ht');
export const hydratightHero = () => import('./Hydratight/common/hydratight-hero');
export const hydratightHeroDynamic = () => import('./Hydratight/common/hydratight-hero-dynamic');
export const hydratightTerms = () => import('./Hydratight/about/hydratight-terms');
export const hydratightPrivacy = () => import('./Hydratight/about/hydratight-privacy');
export const HydratightError = () => import('./Hydratight/common/hydratight-error');

export const hydratightPipelineWhy = () => import('./Hydratight/online-services/hydratight-pipeline-why');
export const hydratightPipelineBenefits = () => import('./Hydratight/online-services/hydratight-pipeline-benefits');
export const hydratightPipelineAccordion = () => import('./Hydratight/online-services/hydratight-pipeline-accordians');

export const hydratightOilGasWhy = () => import('./Hydratight/online-services/hydratight-oil-gas-why');
export const hydratightOilGasBenefits = () => import('./Hydratight/online-services/hydratight-oil-gas-benefits');
export const hydratightOilGasAccordions = () => import('./Hydratight/online-services/hydratight-oil-gas-accordions');

export const hydratightPowerplantWhy = () => import('./Hydratight/online-services/hydratight-powerplant-why');
export const hydratightPowerplantBenefits = () => import('./Hydratight/online-services/hydratight-powerplant-benefits');
export const hydratightpowerplantAccordion = () =>
  import('./Hydratight/online-services/hydratight-powerplant-accordians');

export const hydratightMachiningWhy = () => import('./Hydratight/online-services/hydratight-machining-why');
export const hydratightMachiningBenefits = () => import('./Hydratight/online-services/hydratight-machining-benefits');
export const hydratightMachiningGrid = () => import('./Hydratight/online-services/hydratight-machining-grid');

export const hydratightIndustrialWhy = () => import('./Hydratight/industries-industrial/hydratight-industrial-why');
export const hydratightIndustrialBenefits = () =>
  import('./Hydratight/industries-industrial/hydratight-industrial-benefits');
export const hydratightIndustriesServedGrid = () =>
  import('./Hydratight/industries-industrial/hydratight-industrial-served-grid');

export const hydratightEICTBTrainingGrid = () => import('./Hydratight/EICTB/hydratight-eictb-training-grid');
export const hydratightEICTBAccordion = () => import('./Hydratight/EICTB/hydratight-eictb-accordion');

export const hydratightAcademyTrainingGrid = () => import('./Hydratight/EICTB/hydratight-academy-training-grid');
export const hydratightAcademyAccordion = () => import('./Hydratight/EICTB/hydratight-academy-accordion');

export const hydratightASMETrainingGrid = () => import('./Hydratight/EICTB/hydratight-ASME-training-grid');
export const hydratightASMEAccordion = () => import('./Hydratight/EICTB/hydratight-ASME-accordion');

export const hydratightELearningTrainingGrid = () => import('./Hydratight/EICTB/hydratight-eLearning-training-grid');
export const hydratightELearningAccordion = () => import('./Hydratight/EICTB/hydratight-eLearning-accordion');

export const hydratightEN1591TrainingGrid = () => import('./Hydratight/EICTB/hydratight-EN1591-training-grid');
export const hydratightEN1591Accordion = () => import('./Hydratight/EICTB/hydratight-EN1591-accordion');

export const hydratightTrainingDownloads = () => import('./Hydratight/EICTB/hydratight-training-downloads');

export const hydratightTrainingAvailable = () => import('./Hydratight/training/hydratight-training-available');
export const hydratightTrainingBenefits = () => import('./Hydratight/training/hydratight-training-benefits');
export const hydratightTrainingContact = () => import('./Hydratight/training/hydratight-training-contact');
export const hydratightTrainingGrid = () => import('./Hydratight/training/hydratight-training-grid');

export const HydratightHeaderCollectionNavigationContainer = () =>
  import('./Hydratight/header/hydratight-header-collection-navigation-container');
export const HydratightHeaderCollectionNavigationMobile = () =>
  import('./Hydratight/header/hydratight-header-collection-navigation-mobile');

export const hydratightOurCommitment = () => import('./Hydratight/homepage/hydratight-our-commitment');
export const hydratightBenefits = () => import('./Hydratight/integrity-assurance/hydratight-benefits');
export const hydratightElementsAccordion = () =>
  import('./Hydratight/integrity-assurance/hydratight-elements-accordion');
export const hydratightProgramDetails = () => import('./Hydratight/integrity-assurance/hydratight-program-details');
export const hydratightWhy = () => import('./Hydratight/integrity-assurance/hydratight-why');
export const hydratightWhyBolting = () => import('./Hydratight/online-services/hydratight-why-bolting');
export const hydratightLeakFree = () => import('./Hydratight/integrity-assurance/hydratight-leak-free');

export const hydratightAboutNumbers = () => import('./Hydratight/about/hydratight-about-numbers');
export const hydratightWorkApproach = () => import('./Hydratight/about/hydratight-work-approach');
export const hydratightHowWeWork = () => import('./Hydratight/about/hydratight-how-we-work');
export const hydratightPeople = () => import('./Hydratight/about/hydratight-people');
export const hydratightLocations = () => import('./Hydratight/about/hydratight-locations');
export const hydratightContact = () => import('./Hydratight/about/hydratight-contact');
export const hydratightCertificateVerification = () =>
  import('./Hydratight/training/hydratight-certificate-verification');
export const hydratightContactUpdate = () => import('./Hydratight/about/hydratight-contact-update');
export const hydratightContactNew = () => import('./Hydratight/about/hydratight-contact-new');
export const hydratightTestimonials = () => import('./Hydratight/about/hydratight-testimonials');
export const hydratightTestimonialsPage = () => import('./Hydratight/about/hydratight-testimonials-page');
export const hydratightHistory = () => import('./Hydratight/about/hydratight-history');

export const hydratightProducts = () => import('./Hydratight/about/hydratight-products');

export const HydratightHeaderContainer = () => import('./Hydratight/header/hydratight-header-container');
export const HydratightHeaderTopBar = () => import('./Hydratight/header/hydratight-header-top-bar');
export const HydratightImage = () => import('./Hydratight/common/hydratight-image');
//HT Search
export const hydratightAllProductsTitle = () => import('./Hydratight/product-listing/hydratight-all-products-title');
export const HydratightCategoryContentContainer = () =>
  import('./Hydratight/product-listing/hydratight-category-content-container');
export const HydratightCategoryLongDescription = () =>
  import('./Hydratight/product-listing/hydratight-category-long-description');
export const HydratightCategoryOverview = () => import('./Hydratight/product-listing/hydratight-category-overview');
export const HydratightDynamicBreadcrumbs = () => import('./Hydratight/product-listing/hydratight-dynamic-breadcrumbs');
export const HydratightDynamicProductList = () =>
  import('./Hydratight/product-listing/hydratight-dynamic-product-list');
export const HydratightDynamicProductListingContainer = () =>
  import('./Hydratight/product-listing/hydratight-dynamic-product-listing-container');
export const HydratightFacetedNavigationDesktop = () =>
  import('./Hydratight/product-listing/hydratight-faceted-navigation/desktop');
export const HydratightFacetedNavigationMobile = () =>
  import('./Hydratight/product-listing/hydratight-faceted-navigation/mobile');
export const HydratightPLPInfiniteScroll = () => import('./Hydratight/product-listing/hydratight-plp-infinite-scroll');
export const HydratightPLPTracking = () => import('./Hydratight/product-listing/hydratight-plp-tracking');
export const HydratightProductResultAddToCartButton = () =>
  import('./Hydratight/product-listing/hydratight-product-result-add-to-cart-button');
export const HydratightProductResultImage = () =>
  import('./Hydratight/product-listing/hydratight-product-result-image');
export const HydratightProductResultName = () => import('./Hydratight/product-listing/hydratight-product-result-name');
export const HydratightProductResultPrice = () =>
  import('./Hydratight/product-listing/hydratight-product-result-price');
export const HydratightProductResultQuantity = () =>
  import('./Hydratight/product-listing/hydratight-product-result-quantity');
export const HydratightProductResultRequestQuote = () =>
  import('./Hydratight/product-listing/hydratight-product-result-request-quote');
export const HydratightSearchBoxDesktop = () => import('./Hydratight/product-listing/hydratight-search-box/desktop');
export const HydratightSearchBoxMobile = () => import('./Hydratight/product-listing/hydratight-search-box/mobile');
export const HydratightSearchTermsSummary = () =>
  import('./Hydratight/product-listing/hydratight-search-terms-summary');
export const HydratightSortResults = () => import('./Hydratight/product-listing/hydratight-sort-results');

// LARZEP Widgets     -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
export const LarzepHeaderContainer = () => import('./Larzep/header/larzep-header-container');
export const LarzepHeaderTopBar = () => import('./Larzep/header/larzep-header-top-bar');
export const LarzepHeaderTopBarHome = () => import('./Larzep/header/larzep-header-top-bar-home');
export const LarzepHeaderCollectionNavigationContainer = () =>
  import('./Larzep/header/larzep-header-collection-navigation-container');
export const LarzepHeaderCollectionNavigationMobile = () =>
  import('./Larzep/header/larzep-header-collection-navigation-mobile');

export const LarzepCountryStoreDesktop = () => import('./Larzep/site/larzep-country-store/desktop');
export const LarzepCountryStoreMobile = () => import('./Larzep/site/larzep-country-store/mobile');
export const LarzepError = () => import('./Larzep/site/larzep-error');

export const LarzepProductDetailsContainer = () => import('./Larzep/product/larzep-product-details-container');
export const LarzepProductImageViewer = () => import('./Larzep/product/larzep-product-image-viewer');
export const LarzepProductSpecifications = () => import('./Larzep/product/larzep-product-specifications');
export const LarzepProductDimensions = () => import('./Larzep/product/larzep-product-dimensions');
export const LarzepProductVariants = () => import('./Larzep/product/larzep-product-variants');
export const LarzepProductBestSellers = () => import('./Larzep/product/larzep-product-bestsellers');

export const LarzepHeroBanner = () => import('./Larzep/homepage/larzep-hero-banner');
export const larzepQuickLinks = () => import('./Larzep/homepage/larzep-quick-links');

export const LarzepFooter = () => import('./Larzep/footer/larzep-footer');
export const LarzepFooterHomePage = () => import('./Larzep/footer/larzep-footer-homepage');
export const larzepHistory2 = () => import('./Larzep/homepage/larzep-history-2');

export const LarzepNewProducts = () => import('./Larzep/common/larzep-new-products');
export const LarzepFeedApplications = () => import('./Larzep/common/larzep-feed-applications');
export const LarzepBlog = () => import('./Larzep/common/larzep-blog');
export const LarzepNews = () => import('./Larzep/common/larzep-news');
export const LarzepContainer = () => import('./Larzep/common/larzep-container');
export const larzepFirstPageView = () => import('./Larzep/common/larzep-first-page-view');
export const larzepPromoPopUp = () => import('./Larzep/common/larzep-promo-pop-up');
export const LarzepResponsiveContainer = () => import('./Larzep/common/larzep-responsive-container');
export const LarzepNotifications = () => import('./Larzep/common/larzep-notifications');
export const LarzepContinueShoppingButton = () => import('./Larzep/common/larzep-continue-shopping-button');
export const LarzepSerialNumberLookup = () => import('./Larzep/common/larzep-serial-number-lookup');
export const LarzepDownloads = () => import('./Larzep/common/larzep-downloads');
export const LarzepAboutUs = () => import('./Larzep/common/larzep-about-us');
export const LarzepAssemblies = () => import('./Larzep/common/larzep-assemblies');
export const LarzepSafety = () => import('./Larzep/common/larzep-safety');
export const LarzepLocation = () => import('./Larzep/common/larzep-location');
export const LarzepAllDistributors = () => import('./Larzep/common/larzep-all-distributors');
export const LarzepDistributorSea = () => import('./Larzep/common/larzep-distributor-sea');
export const LarzepQuality = () => import('./Larzep/common/larzep-quality');
export const LarzepTerms = () => import('./Larzep/common/larzep-terms');
export const LarzepHSSEQ = () => import('./Larzep/common/larzep-hsseq');
export const LarzepWarranty = () => import('./Larzep/common/larzep-warranty');
export const LarzepMapPolicy = () => import('./Larzep/common/larzep-map-policy');
export const LarzepPrivacyPolicy = () => import('./Larzep/common/larzep-privacy-policy');
export const LarzepContactUs = () => import('./Larzep/common/larzep-contact-us');
export const LarzepUnitConverter = () => import('./Larzep/common/larzep-unit-conversion');

export const LarzepCpl = () => import('./Larzep/clp/larzep-cpl');
export const LarzepCLPContainer = () => import('./Larzep/clp/larzep-clp-container');
export const LarzepCLPBreadCrumb = () => import('./Larzep/clp/larzep-clp-breadcrumb');
export const LarzepCLPCategoryContent = () => import('./Larzep/clp/larzep-clp-category-content');
export const LarzepCLPChildCategories = () => import('./Larzep/clp/larzep-clp-child-categories');
export const larzepClpValprop = () => import('./Larzep/clp/larzep-clp-valprop');
export const LarzepClpNewsFeed = () => import('./Larzep/clp/larzep-clp-news-feed');

export const larzepHomepageFeeds = () => import('./Larzep/homepage/larzep-homepage-feeds');
export const LarzepHomepageProductGrid = () => import('./Larzep/homepage/larzep-homepage-product-grid');

export const LarzepUserProfileLinksDesktop = () => import('./Larzep/session/larzep-user-profile-links/desktop');
export const LarzepUserProfileLinksMobile = () => import('./Larzep/session/larzep-user-profile-links/mobile');
export const LarzepLogin = () => import('./Larzep/session/larzep-login');

export const LarzepCartContainer = () => import('./Larzep/cart/larzep-cart-container');
export const LarzepMiniCartDesktop = () => import('./Larzep/cart/larzep-mini-cart/desktop');
export const LarzepMiniCartMobile = () => import('./Larzep/cart/larzep-mini-cart/mobile');
export const LarzepCartItemDetails = () => import('./Larzep/cart/larzep-cart-item-details');

export const LarzepSearchBoxDesktop = () => import('./Larzep/product-listing/larzep-search-box/desktop');
export const LarzepSearchBoxMobile = () => import('./Larzep/product-listing/larzep-search-box/mobile');
export const LarzepProductInventoryStatus = () => import('./Larzep/product/larzep-product-inventory-status');
export const LarzepProductComparison = () => import('./Larzep/product/larzep-product-comparison');

export const LarzepCheckoutSingleShippingDetails = () =>
  import('./Larzep/checkout/larzep-checkout-single-shipping-details');
export const LarzepCheckoutLogin = () => import('./Larzep/checkout/larzep-checkout-login');
export const LarzepCheckoutShippingContainer = () => import('./Larzep/checkout/larzep-checkout-shipping-container');
export const LarzepCheckoutCustomerEmail = () => import('./Larzep/checkout/larzep-checkout-customer-email');
export const LarzepCheckoutInvoice = () => import('./Larzep/checkout/larzep-checkout-invoice');
export const LarzepCheckoutOrderConfirmation = () => import('./Larzep/checkout/larzep-checkout-order-confirmation');

export const LarzepProfileOrderHistory = () => import('./Larzep/profile/larzep-profile-order-history');
export const LarzepProfileOrderDetailsContainer = () =>
  import('./Larzep/profile/larzep-profile-order-details-container');
export const LarzepOrderStatus = () => import('./Larzep/profile/larzep-order-status');
export const LarzepProfileRegistration = () => import('./Larzep/profile/larzep-profile-registration');

export const LarzepProfileEmailMarketingPreferencesSummary = () =>
  import('./Larzep/profile/larzep-profile-email-marketing-preferences-summary');
export const LarzepProfileRecentOrders = () => import('./Larzep/profile/larzep-profile-recent-orders');
export const LarzepProfileSavedCardSummary = () => import('./Larzep/profile/larzep-profile-saved-card-summary');
export const LarzepProfileWelcome = () => import('./Larzep/profile/larzep-profile-welcome');
export const LarzepProfileDetailsSummary = () => import('./Larzep/profile/larzep-profile-details-summary');
export const LarzepProfileAddressBook = () => import('./Larzep/profile/larzep-profile-address-book');
export const LarzepAccountAddressBook = () => import('./Larzep/profile/larzep-account-address-book');
export const LarzepProfileAddressBookSummary = () => import('./Larzep/profile/larzep-profile-address-book-summary');
export const LarzepProfileEmailMarketingPreferences = () =>
  import('./Larzep/profile/larzep-profile-email-marketing-preferences');

export const LarzepFacetedNavigationDesktop = () =>
  import('./Larzep/product-listing/larzep-faceted-navigation/desktop');
export const LarzepFacetedNavigationMobile = () => import('./Larzep/product-listing/larzep-faceted-navigation/mobile');
export const LarzepCategoryOverview = () => import('./Larzep/product-listing/larzep-category-overview');
export const LarzepDynamicProductListingContainer = () =>
  import('./Larzep/product-listing/larzep-dynamic-product-listing-container');

export const LarzepPLPInfiniteScroll = () => import('./Larzep/product-listing/larzep-plp-infinite-scroll');
export const LarzepProductComparableModels = () => import('./Larzep/product/larzep-product-comparable-models');
export const LarzepProductUpsell = () => import('./Larzep/product/larzep-product-upsell');
export const LarzepCompatibleProducts = () => import('./Larzep/product/larzep-product-compatible-products');

export const LarzepProductAddToCartButton = () => import('./Larzep/product/larzep-product-add-to-cart-button');

export const LarzepProductCrossSell = () => import('./Larzep/product/larzep-product-cross-sell');
export const LarzepProductAccessories = () => import('./Larzep/product/larzep-product-accessories');
export const LarzepProductSetIncludes = () => import('./Larzep/product/larzep-product-set-includes');
export const LarzepProductRepairParts = () => import('./Larzep/product/larzep-product-repair-parts');
export const LarzepPdpNewsFeed = () => import('./Larzep/product/larzep-pdp-news-feed');

export const LarzepProductResultName = () => import('./Larzep/product-listing/larzep-product-result-name');
export const LarzepProductQuantity = () => import('./Larzep/product/larzep-product-quantity');
export const larzepProductName = () => import('./Larzep/product/larzep-product-name');
export const larzepProductTitle = () => import('./Larzep/product/larzep-product-title');
export const larzepProductLongDescription = () => import('./Larzep/product/larzep-product-long-description');
export const larzepProductPrice = () => import('./Larzep/product/larzep-product-price');
export const LarzepProductResultQuantity = () => import('./Larzep/product-listing/larzep-product-result-quantity');
export const LarzepProductResultAddToCartButton = () =>
  import('./Larzep/product-listing/larzep-product-result-add-to-cart-button');

export const LarzepDynamicProductList = () => import('./Larzep/product-listing/larzep-dynamic-product-list');
export const larzepAllProductsTitle = () => import('./Larzep/product-listing/larzep-all-products-title');
export const LarzepSearchTermsSummary = () => import('./Larzep/product-listing/larzep-search-terms-summary');

export const LarzepProductQuickLinks = () => import('./Larzep/product/larzep-product-quick-links');

export const larzepProductDescriptionSection = () => import('./Larzep/product/larzep-product-description-section');
export const LarzepProductRentalDistributorLinks = () =>
  import('./Larzep/product/larzep-product-rental-distributor-links');
export const LarzepProductRentalDistributorLinksWhereToBuy = () =>
  import('./Larzep/product/larzep-product-links-where-to-buy');

export const LarzepSpecImages = () => import('./Larzep/product/larzep-spec-images');

export const LarzepProductDownloads = () => import('./Larzep/product/larzep-product-downloads');
export const LarzepDistributorsContainer = () => import('./Larzep/distributors/larzep-distributors-container');
export const LarzepDistributorsForm = () => import('./Larzep/distributors/larzep-distributors-form');
export const LarzepDistributorResults = () => import('./Larzep/distributors/larzep-distributor-results');
export const LarzepDistributorMap = () => import('./Larzep/distributors/larzep-distributor-map');

export const LarzepCheckoutBackToPreviousButton = () =>
  import('./Larzep/checkout/larzep-checkout-back-to-previous-button');
export const LarzepCheckoutButton = () => import('./Larzep/checkout/larzep-checkout-button');
export const LarzepCheckoutCompletePaymentButton = () =>
  import('./Larzep/checkout/larzep-checkout-complete-payment-button');
export const LarzepCheckoutContinueToPaymentButton = () =>
  import('./Larzep/checkout/larzep-checkout-continue-to-payment-button');
export const LarzepCheckoutContinueToReviewOrderButton = () =>
  import('./Larzep/checkout/larzep-checkout-continue-to-review-order-button');
export const LarzepCheckoutPaymentsContainer = () => import('./Larzep/checkout/larzep-checkout-payments-container');
export const LarzepCheckoutProgressTracker = () => import('./Larzep/checkout/larzep-checkout-progress-tracker');
export const LarzepCheckoutCreditCard = () => import('./Larzep/checkout/larzep-checkout-credit-card');
export const LarzepCartOrderSummary = () => import('./Larzep/cart/larzep-cart-order-summary');
export const LarzepCartPayInStore = () => import('./Larzep/cart/larzep-cart-pay-in-store');
export const LarzepPromotionCodeEntry = () => import('./Larzep/cart/larzep-promotion-code-entry');
export const LarzepPromotionsDisplay = () => import('./Larzep/cart/larzep-promotions-display');
export const LarzepCheckoutPaymentMethodsContainer = () =>
  import('./Larzep/checkout/larzep-checkout-payment-methods-container');
export const LarzepCheckoutGiftCard = () => import('./Larzep/checkout/larzep-checkout-gift-card');
export const LarzepCheckoutPayInStore = () => import('./Larzep/checkout/larzep-checkout-pay-in-store');

export const LarzepCheckoutOrderConfirmationContainer = () =>
  import('./Larzep/checkout/larzep-checkout-order-confirmation-container');
export const LarzepCheckoutPayLater = () => import('./Larzep/checkout/larzep-checkout-pay-later');
export const LarzepCheckoutPayerAuthentication = () => import('./Larzep/checkout/larzep-checkout-payer-authentication');
export const LarzepCheckoutPendingPaymentHeader = () =>
  import('./Larzep/checkout/larzep-checkout-pending-payment-header');
export const LarzepCheckoutPlaceOrderButton = () => import('./Larzep/checkout/larzep-checkout-place-order-button');
export const LarzepCheckoutRegistration = () => import('./Larzep/checkout/larzep-checkout-registration');
// eslint-disable-next-line max-lines
export const LarzepCheckoutReviewAndPlaceQuotedOrderButton = () =>
  import('./Larzep/checkout/larzep-checkout-review-and-place-quoted-order-button');
export const LarzepCheckoutReviewOrderContainer = () =>
  import('./Larzep/checkout/larzep-checkout-review-order-container');
export const LarzepCheckoutScheduledOrder = () => import('./Larzep/checkout/larzep-checkout-scheduled-order');

export const LarzepShippingInformation = () => import('./Larzep/profile/larzep-shipping-information');
export const LarzepPaymentInformation = () => import('./Larzep/profile/larzep-payment-information');
export const LarzepProfileAddressForm = () => import('./Larzep/profile/larzep-profile-address-form');
export const larzepClpHeader = () => import('./Larzep/clp/larzep-clp-header');

export const LarzepStaticContent = () => import('./Larzep/product/larzep-static-content');

export const LarzepProductBreadcrumbs = () => import('./Larzep/product/larzep-product-breadcrumbs');

export const LarzepSortResults = () => import('./Larzep/product-listing/larzep-sort-results');
export const LarzepProductResultPrice = () => import('./Larzep/product-listing/larzep-product-result-price');
export const LarzepDynamicBreadcrumbs = () => import('./Larzep/product-listing/larzep-dynamic-breadcrumbs');
export const LarzepProductResultRequestQuote = () =>
  import('./Larzep/product-listing/larzep-product-result-request-quote');
export const LarzepProductResultImage = () => import('./Larzep/product-listing/larzep-product-result-image');
export const LarzepGtagCheckout = () => import('./Larzep/gTag/larzep-gtag-checkout');
export const LarzepGtagOrderConfirmation = () => import('./Larzep/gTag/larzep-gtag-order-confirmation');
export const LarzepGtagPDP = () => import('./Larzep/gTag/larzep-gtag-pdp');

export const LarzepDistributorHeader = () => import('./Larzep/training/larzep-distributor-header');
export const LarzepToolOwnerHeader = () => import('./Larzep/training/larzep-tool-owner-header');
export const LarzepOrderConfirmationPaymentInformation = () =>
  import('./Larzep/checkout/larzep-checkout-order-confirmation-payment-information');
export const LarzepOrderConfirmationShippingInformation = () =>
  import('./Larzep/checkout/larzep-checkout-order-confirmation-shipping-information');

export const LarzepCancellationPolicy = () => import('./Larzep/common/larzep-cancellation-policy');
export const LarzepCategoryContentContainer = () =>
  import('./Larzep/product-listing/larzep-category-content-container');

export const LarzepProfileReviewOrders = () => import('./Larzep/profile/larzep-profile-review-orders');
export const LarzepFlangeGuru = () => import('./Larzep/common/larzep-flange-guru');
export const LarzepNewProductForm = () => import('./Larzep/common/larzep-new-products-opt-in');
export const LarzepCanonicalTagsManager = () => import('./Larzep/common/larzep-canonical-tags-manager');

export const LarzepCylinderPumpSpeedGuide = () => import('./Larzep/common/larzep-cylinder-pump-speed-guide');
export const LarzepImage = () => import('./Larzep/common/larzep-image');
